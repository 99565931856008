
import React, { useEffect, useState } from "react";
import { createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import Button from "@mui/material/Button";
import Link from "next/link";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import {
  CardContent,
  Divider,
  useMediaQuery,
} from "@mui/material";

import { useRouter } from "next/router";
import Cookies from "universal-cookie";
import * as ApiCart from "../../api/cart";
import { SweetAlertConfirm } from "../sweetalert";
import { GetNotification, PostNotiMarkasreadById } from "@/api/noti";
import { ImgUrl, isProduction } from "@/config";
import { GetProfile } from "@/api/profile";
import { GetCampaign } from "@/api/campaign";
import UrlAccountSignin from "../navBarResponsive/urlAccountSignin";
import LinkTopage from "../navBarResponsive/linkTopage";
import LinkTopageMobile from "../navBarResponsive/linkTopageMobile";
import LinkTopageDesktop from "../navBarResponsive/linkTopageDesktop"
import useResponsiveItems from "@/hooks/useResponsiveItems";
import Image from "next/image";
import { GetLogo } from "@/api/logo";
import { ApiResponseLogo, ResponseGetLogo } from "@/interfaces/navbar";
import { forEach } from "lodash";

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}


export default function PrimarySearchAppBar() {


  const queryLimit = useResponsiveItems({ xsCount: 4, smCount: 4, mdCount: 6, lgCount: 10, xlCount: 12, xxlCount: 16 });

  const menuId = "primary-search-account-menu";
  const menuIdNoti = "primary-search-account-menu";
  const DefaultProfileImage = '/assets/images/logo/logomaboom.png';
  const mobileMenuId = "primary-search-account-menu-mobile";

  const protocol = window.location.protocol;
  const host = window.location.host;

  const cookies = new Cookies();
  const router = useRouter();
  const keywordSearch = router.query.keyword as string || "";

  const { pathname } = router;
  const getUrl = pathname.split("/")[1] as string;
  const isSpecificPage = pathname === "/campaign/halloween";
  const isMobileScreen = useMediaQuery("(max-width:600px)");
  const token = cookies.get("token");

  //ค้นหา
  // const results = useSelector((state: RootState) => state.search.results);

  const [notification, setNotification] = React.useState<any>([]);
  const [showNotification, setShowNotification] = React.useState<any>([]);
  const nextFullPath = encodeURIComponent(protocol + "//" + host + router.asPath)
  const [anchorElNoti, setAnchorElNoti] = React.useState<boolean>(false);
  const isMenuOpenNoti = Boolean(anchorElNoti);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [profileImage, setProfileImage] = React.useState("");
  const [datamyProfileImg, setDatamyProfileImg] = useState<any>([])
  const [imageSrc, setImageSrc] = useState(datamyProfileImg);
  const [keyword, setKeyword] = useState("");

  const theme = createTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [getDataCampaign, setGetDataCampaign] = useState<any>();
  const [getDataLogo, setGetDataLogo] = useState<ResponseGetLogo[]>();
  const [itemInCart, setItemInCart] = useState(0)

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleSignOut = async () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    const result = await SweetAlertConfirm("คุณต้องการออกจากระบบ ?", "warning");
    if (result.isConfirmed) {
      // cookies.remove("token", { path: "/", maxAge: -1 });
      cookies.remove("token", { path: "/", maxAge: -1 });
      cookies.remove("token", { path: "/", maxAge: -1 });
      cookies.remove("codeRef", { path: "/", maxAge: -1 });
      cookies.remove("phone", { path: "/", maxAge: -1 });
      cookies.remove("phone", { path: "/", maxAge: -1 });
      cookies.remove("img_profile", { path: "/", maxAge: -1 });
      cookies.remove("memberId", { path: "/", maxAge: -1 });
      cookies.remove("username", { path: "/", maxAge: -1 });
      cookies.remove("hasSeenLoginPinModal", { path: "/", maxAge: -1 });
      cookies.remove("PinModalID", { path: "/", maxAge: -1 });
      cookies.remove("pinNew", { path: "/", maxAge: -1 });
      localStorage.clear();
      setProfileImage("");
      router.reload();
    }
  };

  const handleClickNoti = (event: any) => {
    setAnchorElNoti(event.currentTarget);
  };

  const handleMenuCloseNoti = () => {
    setAnchorElNoti(false);
  };
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = (e: any) => {
    setAnchorEl(null);
    handleMobileMenuClose();
    const link = e.currentTarget.getAttribute("data-link");

    if (link) {
      router.push(link + "/?next=" + nextFullPath);
    }
  };

  ////////////////////////////////////// function search ////////////////////////////

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
  };

  const handleClickSearch = () => {
    const currentPath = '/search/allsearch'
    const currentQuery = { ...router.query, category_id: '', keyword: keyword, };

    // Update dataLayer for Google Analytics
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "search",
      search_term: keyword,
    });

    // Log data in non-production environments
    if (!isProduction) {
      console.log("DataLayer updated:", {
        event: "search",
        search_term: keyword,
      });
    }
    router.push({
      pathname: currentPath,
      query: currentQuery,
    }, undefined, { shallow: true });
  };

  // router.query.keyword search
  useEffect(() => {
    if (keywordSearch) {
      setKeyword(keywordSearch);
    }
  }, []);

  /////////////////////////////////// end function search ////////////////////////////////////////

  const Notification = async (token: string) => {
    const res = (await GetNotification(token)) as any;
    if (res?.success === true) {
      setNotification(res.data.notification);
      setShowNotification(res.data.countNotiUnread);
    }
  };

  const handleclickAllNotification = () => {
    router.push("/profile/notification");
    setAnchorElNoti(false);
  };

  const FetchNotiMarkasreadById = async (token: string, dataMarkasreadById: any) => {
    const res = await PostNotiMarkasreadById(token, dataMarkasreadById)
  }

  React.useEffect(() => {
    if (token) {
      Notification(token);
    }
  }, [token]);


  const handleNotification = (notificationId: any, service_type: any, refId: any, orderID: any, type: any) => {
    let navigateToOrderPage = true;

    const dataMarkasreadById = {
      id: notificationId
    };

    FetchNotiMarkasreadById(token, dataMarkasreadById);


    const updatedNotifications = notification.map((notif: any) => {
      if (notif.id === notificationId) {
        navigateToOrderPage = false;

        handleNavigation(type, service_type, refId, orderID);

        setAnchorElNoti(false);
        return {
          ...notif,
          read: true
        };
      }
      return notif;
    });

    setNotification(updatedNotifications);
  };

  const handleNavigation = (type: any, service_type: any, refId: any, orderID: any) => {

    if ([1, 2, 3, 4].includes(Number(service_type))) {
      window.location.href = `/profile/evoucher`;
    }

    else if (type === 1 || type === 3) {
      window.location.href = `/profile/order/${refId}`;
    }

    else if (service_type === "") {
      window.location.href = `/profile/Refunds/${orderID}`;
    }
  };

  const renderMenuNoti = (
    <Menu
      anchorEl={anchorElNoti}
      id={menuIdNoti}
      keepMounted
      open={isMenuOpenNoti}
      onClose={handleMenuCloseNoti}
    >
      <>
        <CardContent style={{ padding: "0px", width: "305px" }}>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            className="txt-bold"
            sx={{ ml: 2 }}
          >
            การแจ้งเตือน
          </Typography>
          <Divider />
          {notification.length === 0 ? (
            <Typography sx={{ textAlign: "center", mt: 2, color: "#C0392B" }}>
              ไม่มีการแจ้งเตือน
            </Typography>
          ) : (
            notification.slice(0, 5).map((data: any, idx: number) => (
              <Grid container key={idx} sx={{ cursor: "pointer" }} my={2}>
                {/* {JSON.stringify(data.service_type)} */}
                <Grid xs={3} md={3} container justifyContent="center" alignItems="center">
                  <Box>
                    {data.status === 2 ? (
                      <>
                        <Avatar src={data?.product_image} alt={data?.product_image} />
                      </>
                    ) : (
                      <>
                        <Badge color="secondary" overlap="circular" badgeContent=" " variant="dot">
                          <Avatar src={data?.product_image} alt={data?.product_image} />
                        </Badge>
                      </>
                    )}

                  </Box>
                </Grid>
                <Grid xs={9} md={9} >
                  <Box onClick={() => handleNotification(data.id, data.service_type, data.ref_id, data.order_id, data.type)}>
                    <Typography >{data.title}</Typography>
                    <Typography
                      color="text.secondary"
                      mr={2}
                      sx={{
                        fontSize: "13px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        WebkitLineClamp: 2,

                      }}
                    >
                      {data.desc}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            ))
          )}
        </CardContent>
        <Divider sx={{ mt: 2 }} />
        <Box sx={{ textAlign: "center" }}>
          <Button onClick={handleclickAllNotification}>
            <Typography
              sx={{
                color: "#DB5A00",
                justifyContent: "center",
                display: "flex",
                textAlign: "center",
                mt: 2,
                fontSize: "14px",
                mb: 2,
                fontFamily: "NotoSans-SemiBold, NotoSansThai-SemiBold !important",

              }}
            >
              ดูการแจ้งเตือนทั้งหมด
            </Typography>
          </Button>
        </Box>
      </>
    </Menu>
  );

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      sx={{
        display: { xs: "block", md: "block" },
      }}
    >
      {isLoading === true ? (
        <>
          <MenuItem onClick={handleMenuClose} data-link="/profile">
            โปรไฟล์
          </MenuItem>
          <MenuItem onClick={handleSignOut}>ออกจากระบบ</MenuItem>
          {/* <MenuItem onClick={handleLogInPin}>สมัครล๊อคอินPIN</MenuItem> */}
        </>
      ) : (
        <>
          <MenuItem onClick={handleMenuClose} data-link="/account/signin">
            เข้าสู่ระบบ
          </MenuItem>
        </>
      )}
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="mails" href="/cart">
          <Badge badgeContent={0} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem onClick={handleClickNoti}>
        <IconButton
          size="large"
          aria-label="notifications"
        // color="inherit"
        >
          <Badge badgeContent={0} color="error">
            <NotificationsOutlinedIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
        >
          <AccountCircleOutlinedIcon />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const fetchDataProfile = async (token: string) => {
    const response = await GetProfile(token);
    if (response?.status === true) {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 3);
      let myProfileImg = ImgUrl.imageUrlCenter + 'uploads/member/' + response?.data.id + '/' + response.data.profileImage;

      setDatamyProfileImg(response?.data.profileImageFullUrl)
      cookies.set("img_profile", myProfileImg, {
        path: "/",
        expires: expirationDate,
      });
      cookies.set("username", response.data.username, {
        path: "/",
        expires: expirationDate,
      });
      cookies.set("memberId", response.data.id, {
        path: "/",
        expires: expirationDate,
      });
      cookies.set("pinNew", response.data.pin, {
        path: "/",
        expires: expirationDate,
      });
    } else {
      cookies.remove("token");
      cookies.remove("username");
      window.location.reload();
    }
  };

  React.useEffect(() => {
    const img_profile = cookies.get("img_profile") || undefined;
    if (token) {
      fetchDataProfile(token);
      setIsLoading(true);
      setProfileImage(img_profile);
    }
  }, [token]);


  const fetchItemInCart = async () => {
    const res = await ApiCart.ItemInCart(token);
    setItemInCart(res?.data)
  };

  React.useEffect(() => {
    const handleRouteChange = (url: string) => { };
    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (token) {
      fetchItemInCart();
    }
  }, []);


  const FetchCampaign = async (token: any) => {
    const response = await GetCampaign(token);
    setGetDataCampaign(response?.data.data || {});
  };
  useEffect(() => {
    FetchCampaign(token);
  }, [token]);

  //GetLogo for show on Navbar
  const FetchLogo = async(token:any)=>{
    const response = await GetLogo(token) as ApiResponseLogo | undefined;
    setGetDataLogo(response?.data);
  }
  useEffect(() => {
    FetchLogo(token);
  }, [token]);
 
  useEffect(() => {

    if (datamyProfileImg !== DefaultProfileImage) {
      setImageSrc(datamyProfileImg);
    }
  }, [datamyProfileImg]);

  const handleImageError = () => {
    setImageSrc(DefaultProfileImage);
  };

  const handleMap = () => {
    router.push("/map" + "/?next=" + nextFullPath)
  }

  return (
    <>
      {/* <Container maxWidth={false} className="container-spacing"> */}
     
      {getUrl === "account" || getUrl === "signin" ? (
        <UrlAccountSignin getDataCampaign={getDataCampaign} />
      ) : getUrl === "printLabel" ? (
        <></>
      ) : (
        <>
          {isMobileScreen ? (
            <>
            
            </>
          ) : (
            <>
              {router.pathname != '/map' && (
                <Container maxWidth={false} className="navbar-top container-spacing">
                  <LinkTopage handleMap={handleMap} />
                </Container>
              )}
            </>
          )}
          {isMobileScreen ? (
            <>
              <LinkTopageMobile
                handleMap={handleMap}
                getDataCampaign={getDataCampaign}
                getDataLogo={getDataLogo}
                isLoading={isLoading}
                itemInCart={itemInCart}
                showNotification={showNotification}
                menuIdNoti={menuIdNoti}
                handleClickNoti={handleClickNoti}
                handleImageError={handleImageError}
                token={token}
                menuId={menuId}
                handleProfileMenuOpen={handleProfileMenuOpen}
                imageSrc={imageSrc}
                isMobileOrTablet={isMobileOrTablet}
                keyword={keyword}
                handleClickSearch={handleClickSearch}
                handleChangeInput={handleChangeInput}
              />
              {renderMobileMenu}
              {renderMenu}
              {renderMenuNoti}
            </>
          ) : (
            <Container
              maxWidth={false} className="bg-white container-spacing navbar-petsploy"
              sx={{
                backgroundImage: getDataCampaign?.background_logo
                  ? `url(${ImgUrl.imageCampaign}/uploads/campaign/${getDataCampaign?.id}/background_logo/${getDataCampaign?.background_logo}) !important`
                  : "",
                backgroundPosition: "center",
                p: "10px 20px",
                position: "relative",
                marginRight: "0px",
              }}
            >
              <LinkTopageDesktop
                getDataLogo={getDataLogo}
                isLoading={isLoading}
                itemInCart={itemInCart}
                showNotification={showNotification}
                menuIdNoti={menuIdNoti}
                handleClickNoti={handleClickNoti}
                handleImageError={handleImageError}
                token={token}
                menuId={menuId}
                handleProfileMenuOpen={handleProfileMenuOpen}
                imageSrc={imageSrc}
                isMobileOrTablet={isMobileOrTablet}
                keyword={keyword}
                handleClickSearch={handleClickSearch}
                handleChangeInput={handleChangeInput}
              />
              {renderMobileMenu}
              {renderMenu}
              {renderMenuNoti}
            </Container>
          )}
        </>
      )}
     
    </>
  );
}
